export var TeamGameType;
(function (TeamGameType) {
    TeamGameType["Unknown"] = "unknown";
    TeamGameType["Beatout"] = "beat out";
    TeamGameType["KraakDeCode"] = "kraak de code";
})(TeamGameType || (TeamGameType = {}));
export var TeamStatus;
(function (TeamStatus) {
    TeamStatus["Waiting"] = "waiting";
    TeamStatus["Active"] = "active";
    TeamStatus["Finished"] = "finished";
    TeamStatus["Archived"] = "archived";
})(TeamStatus || (TeamStatus = {}));
export class Team {
    // eslint-disable-next-line max-lines-per-function
    constructor(_id, id, name, num_slots, members, game_type, start_time, pin, score, state, team_level, team_language, schedule, room_preferences, pause_preferences, games) {
        this._id = _id;
        this.id = id;
        this.name = name;
        this.num_slots = num_slots;
        this.members = members;
        this.game_type = game_type;
        this.team_level = team_level;
        this.start_time = start_time;
        this.pin = pin;
        this.score = score;
        this.state = state;
        this.team_level = team_level;
        this.language = team_language;
        this.schedule = schedule;
        this.room_preferences = room_preferences;
        this.pause_preferences = pause_preferences;
        this.games = games;
    }
    static load(data) {
        return new Team(data._id, data.id, data.name, data.num_slots, data.members, data.game_type, data.start_time, data.pin, data.score, data.state, data.team_level, data.language, data.schedule, data.room_preferences, data.pause_preferences, data.games);
    }
    get duration() {
        const num_pauses = this.schedule.filter(item => item === "pause").length;
        return (this.num_slots + num_pauses) * 20 * 60;
    }
}
export const getScheduledRoomIdForTeam = (team, timestamp) => {
    var _a;
    // TODO: make generic
    const slot_duration = 20 * 60;
    if (team.start_time === null)
        return null;
    if (timestamp < team.start_time)
        return null;
    const slot = Math.floor((timestamp - team.start_time) / slot_duration);
    if (slot >= team.schedule.length)
        return null;
    return (_a = team.schedule[slot]) !== null && _a !== void 0 ? _a : null;
};
export const setScheduledRoomIdForTeam = (team, timestamp, roomId) => {
    // TODO: make generic
    const slot_duration = 20 * 60;
    if (team.start_time === null) {
        team.start_time = timestamp;
    }
    if (timestamp < team.start_time)
        return;
    const slot = Math.floor((timestamp - team.start_time) / slot_duration);
    while (slot > team.schedule.length) {
        team.schedule.push(null);
    }
    team.schedule[slot] = roomId;
};
const scorefactor = {
    birdsroom: 0.75,
    lasermaze: 3,
    phoneroom: 5,
    coderoom: 3,
    searchroom: 3.5,
    reactionroom: 1.25,
    arcaderoom: 4,
    spiderroom: 5,
    jungleroom: 0.75,
    repeatroom: 3,
    bedroom: 2.25,
    gridroom: 2
};
export const getScoresForTeam = (team) => {
    const scores = {};
    team.games.forEach(game => {
        var _a, _b;
        const room = game.room;
        const factor = (_a = scorefactor[room]) !== null && _a !== void 0 ? _a : 1;
        const score = (game.level + 1) * factor;
        if (score > 0 && ((_b = scores[room]) !== null && _b !== void 0 ? _b : 0 < score)) {
            scores[room] = Math.round(score);
        }
    });
    return scores;
};
