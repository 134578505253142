/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-len */
export const DutchTranslation = {
    levelUp: 'Level omhoog',
    levelUpTagline: 'Succes met het volgende level!',
    levelProgress: 'Level Vooruitgang',
    pushButton: 'Druk op een willekeurig knop om te starten',
    pushButtonIlluminated: 'Druk op een verlichte knop om te starten',
    attemptsNeeded: 'poging(en) nodig gehad',
    attemptsLeft: 'pogingen resterend in dit level',
    gamePausesNewInstructions: 'Bij start van een nieuw level pauzeert het spel.'
        + 'Nieuwe spelinstructies verschijnen op het scherm.',
    gameStartsInMoment: 'Het spel start over',
    red: 'Rood',
    blue: 'Blauw',
    green: 'Groen',
    yellow: 'Geel',
    reachedLevel: 'Whauw, jullie zijn tot level {level} geraakt!',
    introductionTitle: 'Speluitleg',
    introductionGameStarts: 'Het spel start na de uitleg',
    reactionRoomHighLevel: 'Dat waren bliksemsnelle reacties!<br/>Proficiat!',
    reactionRoomDescrLevel1: 'Spelverkenning, kennismaking met het spel<br/> Druk de knop in binnen de <b>25 seconden</b>',
    reactionRoomDescrLevel2: 'Druk de volgende knop in binnen de <b>10 seconden</b>',
    reactionRoomDescrLevel3: 'Druk de volgende knop in binnen de <b>7 seconden</b>',
    reactionRoomDescrLevel4: 'Druk de toegelaten knop in binnen de <b>7 seconden</b>.' +
        '<br/>Opgelet: de aangeduide kleur op het scherm mag je <b>NIET</b> indrukken.',
    reactionRoomDescrLevel5: 'Druk de toegelaten knop in binnen de <b>10 seconden</b>.' +
        '<br/>Opgelet: de aangeduide kleur op het scherm mag je <b>NIET</b> indrukken.' +
        '<br/>Deze kleur wijzigt bij iedere drukactie.',
    reactionRoomDescrLevel6: 'Druk de toegelaten knop in binnen de <b>7 seconden</b>.' +
        '<br/>Opgelet: de aangeduide kleur op het scherm mag je <b>NIET</b> indrukken.' +
        '<br/>Deze kleur wijzigt bij iedere drukactie.',
    reactionRoomDescrLevel7: 'Druk de toegelaten knop in binnen de <b>10 seconden</b>.' +
        '<br/>Opgelet: de kleuren op het scherm mag je <b>NIET</b> indrukken.' +
        '<br/>Deze kleur wijzigt bij iedere drukactie.',
    reactionRoomDescrLevel8: 'Druk de toegelaten knop in binnen de <b>7 seconden</b>.' +
        '<br/>Opgelet: de <b>geschreven</b> kleuren op het scherm mag je <b>NIET</b> indrukken.' +
        '<br/>Deze kleur wijzigt bij iedere drukactie.',
    reactionRoomDescrLevel9: 'Druk de toegelaten knop in binnen de <b>5 seconden</b>.' +
        '<br/>Opgelet: de <b>geschreven</b> kleuren op het scherm mag je <b>NIET</b> indrukken.' +
        '<br/>Deze kleur wijzigt bij iedere drukactie.',
    reactionRoomDescrLevel10: 'Druk de toegelaten knop in binnen de <b>4 seconden</b>.' +
        '<br/>Opgelet: de <b>geschreven</b> kleuren op het scherm mag je <b>NIET</b> indrukken.' +
        '<br/>Deze kleur wijzigt bij iedere drukactie.',
    codeRoomChallenge: 'Geef de oplossing van een raadsel en stijg een level.',
    birdsProgress: 'Team vs Kraaien',
    birdsThrowBallToStart: 'Werp een bal in een gekleurde opening om te starten',
    birdsSubtitle: 'Kraaien vliegen naar binnen. Kunnen jullie deze tegenhouden?',
    birdsChallenge: 'Werp ballen in de openingen tot uit zijn.',
    codeRoomIncorrect: 'Ingegeven code is niet correct',
    codeRoomSuccess: 'Sherlock Holmes staat verlegen naast jullie.',
    laserSubtitle: 'Geraken jullie over zonder de lasers te raken?',
    laserWait: 'Wacht om te starten',
    laserBadgeToStart: 'Badge om deze level te starten.',
    laserFailed: 'Jammer, niet gelukt.<br>Keer terug en badge om te starten.',
    laserReturnToStart: 'Ga terug naar de startzone.<br>Wij kijken het spelgebied na.',
    laserCalibrate: 'Wacht om te starten<br>Klaarmaken volgend level',
    laserCloseDoor: 'Sluit de deur voordat je start.',
    laserSuccess: 'Jullie zijn meester inbrekers!',
    laserDescription: 'Geraken jullie allen doorheen de maze?<br/>Bij het raken van een straal verlies je <br><b>35 punten</b>.',
    phoneTitle: 'Telefooncentrale',
    phoneSubtitle: 'Verbind de personen door de knoppen bij hun foto tegelijk in te drukken',
    phoneCombinationCorrect: 'Combinatie gemaakt!<br/>Volgende oproep komt zo meteen.',
    phoneCombinationWrong: '<b>Niet gelukt</b><br>Laat alle knoppen los en wacht op een nieuwe combinatie.',
    phoneCloseDoor: 'Deur mag niet worden geopend',
    searchSubtitle: 'Zoek wie, wat en waar aan het repareren is.',
    searchStartText: 'Zoek wie aanwezig is',
    searchCorrect: 'Combinatie gemaakt!<br/>Volgende oproep komt zo meteen.',
    searchWrong: '<b>Niet gelukt</b><br>Laat alle knoppen los en wacht op een nieuwe combinatie.',
    searchRoomDescrLevelPersons: 'Vind <b>EEN GROENE persoon</b> en druk deze in op het paneel.' +
        '<br><br>OF<br><br>' +
        'Vind <b>DRIE RODE personen</b> en druk op de knop van de niet gekleurde persoon.',
    searchRoomDescrLevelPersonsBroken: 'Ga op zoek naar de persoon <br/><b>EN</b> naar wat hij aan het herstellen is',
    searchRoomDescrLevelPersonsBrokenTools: 'Ga op zoek naar de persoon <br/><b>EN</b> naar wat hij aan het herstellen is <br>' +
        '<b>EN</b> naar het gerief waarmee hij dit herstelt',
    arcadeRoomHighLevel: 'Je merkt dat jullie vroeger véél gespeeld hebben!',
    tetrisLines: 'Maak {lines} rij voor het volgende level.',
    tetrisLinesCompleted: 'Reeds {linesCompleted} gemaakt.',
    spiderCrawl: 'Kruip <b>één per één</b> door een <b>groene opening zonder de randen te raken</b>.<br> \
  <br>Zodra je start zal de opening geel kleuren.',
    spiderAfterCrawl: 'Druk op de knoppen in start- en eindbox na iedere doorgang.',
    spiderCountPlayers: 'We tellen met hoeveel jullie zijn',
    congratsPersonOver: 'Proficiat, weer iemand over!',
    returnAllToStart: 'Ga <b>allemaal</b> naar de startzone',
    allPressButtons: 'Druk ieder een set knoppen in',
    pressButtonsAbove: 'Druk op beide knoppen die <b>boven elkaar</b> staan en houdt deze ingedrukt.',
    keepHoldingButtons: 'Houd de knoppen gedurende <b>{time}</b> seconden ingedrukt.',
    buttonsPressed: 'knoppen ingedrukt',
    pressThisButton: 'Druk op deze knop',
    jungleSubtitle: 'Kunnen jullie de vijandige jungle-kolonie tegenhouden?',
    jungleThrowBallToStart: 'Werp een bal tegen een actief wezentje om te starten',
    jungleChallenge: 'Werp ballen tegen de wezens met verlichte ogen tot deze allen zijn uitgeschakeld',
    repeatRoomHighLevel: 'Knappe koppen in dit team, straf onthouden!',
    repeatSerieWithButtonNext: 'Herhaal de rij met deze knop achteraan',
    startSerieWithButtonNext: 'Start de nieuwe rij met deze knop',
    allRoomsPlayed: 'Jullie hebben alle geplande kamers gespeeld.',
    allRoomsPlayedKokiBar: 'Ga naar de KokiBar om scores te zien!',
    kokibarPause: 'KokiBar voor een pauze',
    nextRoomPlanned: 'Jullie kunnen nu naar de {nextRoom} gaan.',
    succeeded: 'Proficiat!',
    notSucceeded: 'Ai ai, helaas deze keer niet.',
    startZone: 'Startzone',
    endZone: 'Eindzone',
    ofThe: 'van de',
    persons: 'personen',
    doNotStandOnPlatform: 'Niet op het platform staan',
    gridRoomIncorrect: 'Lap, niet gelukt',
    gridSuccess: 'Dat waren straffe toeren!',
    gridInGameStopped: 'Het level gaat starten, zorg dat je op de groene tegels staat',
    gridRemainingTiles: 'Nog <b>{remainingTiles} tegels</b> te gaan',
    stoppedThisRoomPlayed: 'Deze kamer net gespeeld?',
    stoppedTeamCanGoTo: 'Team {teamName} kan nu naar de <b>{nextRoom}</b>',
    stoppedAllRoomsPlayed: 'Jullie hebben alle geplande kamers gespeeld.<br>Ga naar de KokiBar om scores te zien!'
};
