import { API } from '@/BeatoutAPI';
import { moduleActionContext } from '@/store';
import { Team } from '@/types/teams';
const state = {
    schedules: {}
};
const getters = {};
const mutations = {};
const actions = {
    async getSchedule(_context, options) {
        console.log('>>>> checkSchedule getSchedule function');
        const response = await API.getRequest().post(`scheduler`, JSON.stringify(options));
        console.log('>>>> checkSchedule getSchedule function: ' + response);
        return {
            changed_team_ids: response.data.changed_team_ids,
            teams: response.data.teams.map((data) => Team.load(data))
        };
    }
};
const scheduleModule = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
export default scheduleModule;
export const scheduleModuleActionContext = (context) => moduleActionContext(context, scheduleModule);
