import { createRouter, createWebHistory } from 'vue-router';
const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/room/:roomid',
            name: 'Room',
            component: () => import(/* webpackChunkName: "room" */ '@/components/Rooms/Room'),
            props: true,
            meta: {
                title: 'Beat Out! Game'
            }
        },
        {
            path: '/mybeatout',
            name: 'MyBeatOut',
            component: () => import(/* webpackChunkName: "mybeatout" */ '@/views/MyBeatOut'),
            meta: {
                title: 'My Beat Out'
            }
        },
        {
            path: '/reset',
            name: 'Reset',
            component: () => import(/* webpackChunkName: "reset" */ '@/views/Reset'),
            meta: {
                title: 'Reset'
            }
        },
        {
            path: '/score',
            name: 'Score',
            component: () => import(/* webpackChunkName: "score" */ '@/views/ScorePage'),
            meta: {
                title: 'Beat Out! Score'
            }
        },
        {
            path: '/tetris',
            name: 'Tetris',
            component: () => import(/* webpackChunkName: "score" */ '@/views/Tetris'),
            meta: {
                title: 'Tetris Tryout'
            }
        },
        {
            path: '/',
            name: 'Root',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/components/Dashboard'),
            meta: {
                title: 'Beat Out!',
            },
            children: [
                {
                    path: '/rooms',
                    name: 'Rooms',
                    component: () => import(/* webpackChunkName: "rooms" */ '@/views/RoomsTab'),
                    meta: {
                        title: 'Beat Out! Rooms'
                    }
                },
                {
                    path: '/teams',
                    name: 'Teams',
                    component: () => import(/* webpackChunkName: "teams" */ '@/views/TeamsTab'),
                    meta: {
                        title: 'Beat Out! Teams'
                    }
                },
                {
                    path: '/system',
                    name: 'System',
                    component: () => import(/* webpackChunkName: "system" */ '@/views/SystemTab'),
                    meta: {
                        title: 'Beat Out! System'
                    }
                },
                {
                    path: '/genericschedule',
                    name: 'Generic Schedule',
                    component: () => import(/* webpackChunkName: "genericschedule" */ '@/views/GenericScheduleTab'),
                    meta: {
                        title: 'Day Schedule'
                    }
                },
                {
                    path: '/users',
                    name: 'Users',
                    component: () => import(/* webpackChunkName: "users" */ '@/views/UsersTab'),
                    meta: {
                        title: 'Beat Out! Users'
                    }
                },
                {
                    path: '/files',
                    name: 'Files',
                    component: () => import(/* webpackChunkName: "files" */ '@/views/FilesTab'),
                    meta: {
                        title: 'Beat Out! Files'
                    }
                },
                {
                    path: '/instagram',
                    name: 'Instagram',
                    component: () => import(/* webpackChunkName: "instagram" */ '@/views/InstagramTab'),
                    meta: {
                        title: 'Beat Out! Instagram'
                    }
                },
                {
                    path: '/rfid',
                    name: 'RFID',
                    component: () => import(/* webpackChunkName: "instagram" */ '@/views/RFIDTab'),
                    meta: {
                        title: 'Beat Out! RFID'
                    }
                },
                {
                    path: '/settings',
                    name: 'Settings',
                    component: () => import(/* webpackChunkName: "settings" */ '@/views/SettingsTab'),
                    meta: {
                        title: 'Beat Out! Settings'
                    }
                },
                {
                    path: '/outdoor',
                    name: 'Outdoor',
                    component: () => import(/* webpackChunkName: "cv" */ '@/components/Dashboard'),
                    meta: {
                        title: 'Beat Out! Outdoor'
                    }
                },
                {
                    path: '/cameras',
                    name: 'Cameras',
                    component: () => import(/* webpackChunkName: "cv" */ '@/views/CameraTab'),
                    meta: {
                        title: 'Beat Out! Camera'
                    }
                }
            ]
        }
    ]
});
router.beforeEach((to, _from) => {
    var _a, _b;
    document.title = (_b = String((_a = to.meta) === null || _a === void 0 ? void 0 : _a.title)) !== null && _b !== void 0 ? _b : 'Beat Out!';
});
export default router;
